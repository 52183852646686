<template>
  <div class="home">
    <v-container>
      <Menu />
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu.vue";

export default {
  name: "Home",
  components: {
    Menu,
  },
};
</script>
