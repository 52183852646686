<template>
  <v-container fluid>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">Home</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-row>
      <v-col cols="12" sm="6" md="6" v-if="group === 'admin' || group === 'user'">
        <v-btn x-large block to="/assign"> <v-icon left>mdi-dip-switch</v-icon> タリー割当設定 </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="group === 'admin' || group === 'user'">
        <v-btn x-large block to="/camerasetting">
          <v-icon left>mdi-video-wireless-outline</v-icon> 子機設定
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6" v-if="group === 'admin' || group === 'user'">
        <v-btn x-large block to="/device"> <v-icon left>mdi-view-list-outline</v-icon> デバイス一覧 </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-btn x-large block to="/map"> <v-icon left>mdi-map-marker-outline</v-icon> マップ </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6" v-if="group === 'admin'">
        <v-btn x-large block to="/AdminDevice"> 【管理用】デバイス設定 </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  data: () => ({
    group: "",
  }),

  methods: {},
  async created() {
    this.user = await Auth.currentAuthenticatedUser();
    this.group = await this.user.signInUserSession.accessToken.payload['cognito:groups'][0];
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
